/**
    DEFAULT COOKIE BYTE STYLING

    Here we define basic styles for buttons, checkboxes and tooltips. You can
    reuse each or replace them with your own to match your site's design.
 */

.ddm {
    button {
        -webkit-appearance: button;
        background: none;
        cursor: pointer;

        &:focus {
            outline: 2px solid transparent;
            outline-offset: 2px;
        }
    }

    * {
        box-sizing: border-box;
    }
}

#ddmcm-button-all, .ddmcc-button-accept {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 1.25rem;

    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    transition: all ease-in-out 300ms;
}

.ddmcm input[type='checkbox'] {
    display: none;

    & + label {
        position: relative;
        display: inline-block;
        padding-left: 2.5rem;

        cursor: pointer;
        user-select: none;
    }

    &:disabled + label {
        color: #a3a3a3; /* Greyed out */
    }

    & + label::before {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 0;
        left: 0;

        border: 2px solid #000; /* Primary color */
        content: '';
        transition: all .12s, border-color .08s;
    }

    &:disabled + label::before {
        border-color: #a3a3a3; /* Greyed out */
    }

    &:checked + label::before {
        width: 0.75rem;
        top: 0.5rem;
        transform: rotate(45deg) translate(-50%, -50%);

        background-color: transparent;
        border-color: transparent;
        border-right-color: #000; /* Primary color */
        border-bottom-color: #000; /* Primary color */
        border-right-width: 2px;
        border-bottom-width: 2px;
    }

    &:checked:disabled + label::before {
        border-right-color: #a3a3a3; /* Greyed out */
        border-bottom-color: #a3a3a3; /* Greyed out */
    }
}

/* Big thanks to Ghosh for this amazing tooltip (npm: microtip) */
.ddmcm [aria-label][role~=tooltip] {
    position: relative;
    width: 1rem;
    height: 1rem;
    vertical-align: super;
    /* Thanks to bootstrap icons for this cool info icon (name: info-circle) */
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23000' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/%3E%3C/svg%3E") no-repeat;

    &::before, &::after {
        position: absolute;
        transform-origin: top;
        will-change: transform;
        box-sizing: border-box;

        z-index: 10;
        opacity: 0;
        backface-visibility: hidden;
        pointer-events: none;
        transition: all .18s ease-in-out;

        /* default tooltip position: left */
        top: 50%;
        left: auto;
        right: 100%;
        bottom: auto;
        transform: translate3d(10px, -50%, 0);
    }

    /* The little arrow, showing where the tooltip comes from */
    &::before {
        background-size: 100% auto !important;
        content: '';

        /* default tooltip position: left */
        background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E') no-repeat;
        width: 6px;
        height: 18px;
        margin-right: 5px;
        margin-bottom: 0;
    }

    &::after {
        width: 60vw;
        max-width: 260px;
        padding: .5em 1em;
        box-sizing: content-box;

        border-radius: 4px;
        content: attr(aria-label);
        font-size: 13px;
        color: #fff;
        background: rgba(17, 17, 17, .9);

        /* default tooltip position: left */
        margin-right: 11px;
    }

    &:hover, &:focus {
        &::before, &::after {
            opacity: 1;
            pointer-events: auto;

            /* default tooltip position: left */
            transform: translate3d(0, -50%, 0);
        }
    }

    /* Everything above tablet; Tooltip position: top-right */
    @media screen and (min-width: 48rem) {
        &::before, &::after {
            top: auto;
            right: auto;
        }

        &::before {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
            width: 18px;
            height: 6px;
            bottom: 100%;
            left: 50%;
            margin-right: auto;
            margin-bottom: 5px;
            transform: translate3d(-50%, 0, 0);
        }

        &::after {
            bottom: 100%;
            left: 50%;
            margin-right: auto;
            margin-bottom: 11px;
            transform: translate3d(calc(0% + -16px), 0, 0);
        }

        &:hover {
            &::before {
                transform: translate3d(-50%, -5px, 0);
            }

            &::after {
                transform: translate3d(calc(0% + -16px), -5px, 0);
            }
        }
    }
}

/** Adds some basic styling to the modal's content itself.
 *  Everything after this line can be changed without a doubt. */
.ddmcm-content, .ddmcc-content {
    margin: 1.5rem;

    h2, p {
        margin-bottom: 1.25rem;
    }

    /** Let's play safe: H2 is sometimes used for enormously huge texts,
     * so we define our own size, everything else is up to the other styles. */
    h2 {
        font-size: 1.125rem;
        line-height: 1.75rem;
        @apply text-lg lg:text-xl;
    }

    p, label {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    @media screen and (min-width: 64rem) {
        h2 {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        p, label {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ddmcm [aria-label][role~=tooltip] {
        vertical-align: inherit;
    }
}